import React from "react";
import _ from "lodash";
import { Table, Button, Popconfirm, Modal } from "antd";
import { notification, Form, Input, Select } from "antd";
import { call } from "@/actions/axios";
import { useDispatch } from "react-redux";
import ReactJson from "react-json-view";

const Templates = ({ app }) => {
  const [loading, setLoading] = React.useState(true);
  const [preview, setPreview] = React.useState(null);
  const [adding, setAdding] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const dispatch = useDispatch();

  const fetch = React.useCallback(async () => {
    setLoading(true);
    const { data } = await dispatch(
      call({
        url: "templates/" + app._id,
        method: "GET",
      })
    );
    setLoading(false);
    setTemplates(data);
  }, [app, dispatch]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const onDelete = async (id) => {
    setLoading(true);
    try {
      await dispatch(
        call({
          url: "templates/" + id,
          method: "DELETE",
        })
      );
      await fetch();
      notification.success({
        message: "Успешно!",
        description: "Шаблонное сообщение удалено",
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      let message = "Не удалось удалить шаблонное сообщение";
      if (e && e.response && e.response.data) message = e.response.data.message;
      notification.error({
        message: "Ошибка!",
        description: message,
      });
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
    },
    {
      title: "Название",
      dataIndex: "name",
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => setPreview({ title: text, meta: record.meta })}
          >
            {text}
          </Button>
        </>
      ),
    },
    {
      title: "Текст",
      dataIndex: "text",
    },
    {
      title: "Язык",
      dataIndex: "locale",
    },
    {
      title: "Статус",
      dataIndex: ["meta", "status"],
    },
    {
      title: "Действие",
      dataIndex: "operation",
      render: (_, record) => (
        <Popconfirm title="Уверены?" onConfirm={() => onDelete(record._id)}>
          <a>Удалить</a>
        </Popconfirm>
      ),
    },
  ];

  const onSave = async (data) => {
    setLoading(true);
    try {
      await dispatch(
        call({
          url: "templates/" + app._id,
          method: "POST",
          data: {
            template: data,
          },
        })
      );
      await fetch();
      setLoading(false);
      setAdding(false);
      notification.success({
        message: "Успешно!",
        description: "Шаблонное сообщение добавлено",
      });
    } catch (e) {
      setLoading(false);
      let message = "Не удалось добавить шаблонное сообщение";
      if (e && e.response && e.response.data) {
        message = JSON.stringify(e.response.data);
      }
      Modal.error({
        title: "Ошибка!",
        content: message,
      });
    }
  };

  return (
    <>
      <Modal
        title={`Просмотр шаблона - ${preview && preview.title}`}
        visible={!!preview}
        width="100%"
        destroyOnClose={true}
        forceRender={true}
        footer={null}
        onCancel={() => setPreview(null)}
      >
        {preview && <ReactJson src={preview.meta} theme="monokai" />}
      </Modal>
      <p>
        <Button type="primary" onClick={() => setAdding(true)}>
          Добавить шаблон
        </Button>
        <Button style={{ marginLeft: 10 }} type="primary" onClick={fetch}>
          Обновить
        </Button>
      </p>
      <Template
        onSave={onSave}
        adding={adding}
        loading={loading}
        onCancel={() => setAdding(false)}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={templates}
        pagination={false}
        rowKey="_id"
        bordered
      />
    </>
  );
};

const Template = (props) => {
  const [examples, setExamples] = React.useState([]);
  const [example, setExample] = React.useState([]);
  const { adding, onSave, onCancel, loading } = props;
  const form = React.useRef();

  const onEdit = (data) => {
    const { updated_src } = data;
    setExample(updated_src);
  };
  const onAdd = (data) => {
    const { updated_src } = data;
    setExample(updated_src);
  };
  const onDelete = (data) => {
    const { updated_src } = data;
    setExample(updated_src);
  };

  const onChange = (v) => {
    if (Object.keys(v)[0] === "example") set_example(v.example);
  };

  const dispatch = useDispatch();

  const fetch = React.useCallback(async () => {
    const { data } = await dispatch(
      call({
        url: "templates/examples/list",
        method: "GET",
      })
    );
    await setExamples(data);
    set_example("text");
  }, [adding, dispatch]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const set_example = (name) => {
    const item = _.find(examples, { name });
    if (item) {
      delete item._id;
      delete item.id;
      setExample(item.meta);
    }
  };

  const onOk = async () => {
    const values = await form.current.validateFields();
    delete values.example;
    const data = { ...values, components: example };
    onSave(data);
  };

  return (
    <Modal
      title="Новый шаблон"
      visible={adding}
      width={800}
      className="new__template"
      destroyOnClose={true}
      forceRender={true}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form
        ref={form}
        layout="vertical"
        onFinish={onSave}
        initialValues={{ example: "text" }}
        onValuesChange={onChange}
      >
        <Form.Item
          name="category"
          label="Категория"
          rules={[{ required: true, message: "Поле Категория обязательно" }]}
        >
          <Input disabled={loading} placeholder="Введите категорию" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Название"
          rules={[{ required: true, message: "Поле Название обязательно" }]}
        >
          <Input disabled={loading} placeholder="Введите название" />
        </Form.Item>
        <Form.Item
          name="language"
          label="Язык"
          rules={[{ required: true, message: "Поле Язык обязательно" }]}
        >
          <Input disabled={loading} placeholder="Введите язык" />
        </Form.Item>
        <Form.Item name="example" label="Примеры сообщения">
          <Select disabled={loading}>
            {examples.map((option) => (
              <Select.Option key={option.name} value={option.name}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {loading || (
        <ReactJson
          src={example}
          name="components"
          enableClipboard={false}
          onEdit={onEdit}
          onAdd={onAdd}
          onDelete={onDelete}
          theme="monokai"
        />
      )}
    </Modal>
  );
};

export default Templates;
