import React, { Component } from "react";
import { notification, Form, Modal } from "antd";
import { Switch, Input } from "antd";
import { connect } from "react-redux";
import { call } from "@/actions/axios";
import { REMOVE_APP_BY_PARAM, PUSH_APP } from "@/actions/app";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

class Settings extends Component {
  state = {
    loading: false,
  };
  handleSave = () => this.form.submit();
  onFinish = async () => {
    const { app } = this.props;
    const values = this.form.getFieldsValue();
    this.setState({ loading: true });
    if (app._id) await this.props.onUpdate(app._id, values);
    this.setState({ loading: false });
    this.props.reset();
  };
  onDelete = async () => {
    try {
      const { app } = this.props;
      await this.props.call({
        url: "app/" + app._id,
        method: "DELETE",
      });
      this.props.REMOVE_APP_BY_PARAM(["apps"], "_id", app._id);
      this.props.reset();
    } catch (e) {
      notification.error({
        message: "Ошибка",
        description: "Удаление приложения не удалось. Попробуйте еще раз!",
      });
    }
  };
  render() {
    const { app } = this.props;
    if (!app) return null;
    const { loading } = this.state;
    return (
      <Modal
        title="Параметры приложения"
        visible={!!app}
        okText="Сохранить"
        onOk={this.handleSave}
        width={700}
        destroyOnClose={true}
        onCancel={this.props.reset}
      >
        <Form
          ref={(f) => (this.form = f)}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ ...app }}
          onFinish={this.onFinish}
        >
          <Form.Item name="status" label="Активно" valuePropName="checked">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              disabled={loading || (app && !app._id)}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: `Поле Название обязательно`,
              },
            ]}
            name="name"
            label="Название приложения"
          >
            <Input disabled={loading} placeholder="Введите текст" />
          </Form.Item>
          <Form.Item name="webhook" label="Адрес Webhook">
            <Input disabled={loading} placeholder="Введите URL" />
          </Form.Item>
          <Form.Item name="message" label="Сообщение о недоступности">
            <Input.TextArea disabled={loading} placeholder="Введите текст" />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default connect(null, { call, REMOVE_APP_BY_PARAM, PUSH_APP })(Settings);
