import React from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

const YMAP = ({ latitude, longitude }) => (
  <YMaps>
    <Map state={{ center: [latitude, longitude], zoom: 13 }}>
      <Placemark geometry={[latitude, longitude]} />
    </Map>
  </YMaps>
);

export default YMAP;
