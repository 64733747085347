import React from "react";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./index.scss";

const Pagination = (props) => {
  const { limit, currentPage, total } = props;
  const { hasPrevPage, hasNextPage } = props;
  const { onPage } = props;
  let current = limit * currentPage;
  if (current > total) current = total;
  return (
    <div className="pagination__wrapper box__shadow">
      <div className="pagination__title">{`Показано ${current} из ${total}`}</div>
      <Button
        shape="circle"
        onClick={() => onPage(-1)}
        disabled={!hasPrevPage}
        icon={<LeftOutlined />}
      />
      <Button
        shape="circle"
        onClick={() => onPage(1)}
        disabled={!hasNextPage}
        icon={<RightOutlined />}
      />
    </div>
  );
};

Pagination.defaultProps = {
  total: 0,
  limit: 0,
  currentPage: 0,
  hasPrevPage: true,
  hasNextPage: false,
};

export default Pagination;
