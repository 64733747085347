import React, { Component } from "react";
import { connect } from "react-redux";
import { AUTH, SET_AUTH } from "@/actions/auth";
import { Form, Input, Button, notification } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { Row, Col, Alert } from "antd";

import "./index.scss";

class Login extends Component {
  state = {
    loading: false,
    state: "login",
  };
  componentDidMount() {
    const [el] = document.getElementsByClassName("main__content__layout");
    el.classList.add("login");
  }
  componentWillUnmount() {
    const [el] = document.getElementsByClassName("main__content__layout");
    el.classList.remove("login");
  }
  handleSubmit = async (values) => {
    const { state } = this.state;
    if (state === "login") await this.props.AUTH("auth/login", values);
    else if (state === "forgot")
      await this.props.AUTH("auth/forgot", values, false).then(() => {
        notification.success({
          message: "Изменение пароля!",
          description: "На почту высланы данные для изменения пароля",
        });
      });
  };
  changeState = () => {
    const state = this.state.state === "login" ? "forgot" : "login";
    this.setState({ state });
  };
  render() {
    const { message, isLoading } = this.props;
    const { state } = this.state;
    return (
      <Row
        justify="center"
        align="middle"
        type="flex"
        className="login-form-container"
      >
        <Col xl={6} xxl={6} lg={8} md={8} className="login-form-box">
          <Form onFinish={this.handleSubmit} className="login-form">
            <Form.Item className="login-form-logo">
              <NavLink to="/" className="logo">
                <img alt="logo" src={require("@/assets/images/logo.svg")} />
              </NavLink>
            </Form.Item>
            {message && (
              <Form.Item>
                <Alert message={message} type="error" showIcon />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Введите почтовый адрес!" }]}
            >
              <Input
                prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Почтовый адрес"
                disabled={isLoading}
              />
            </Form.Item>
            {state === "login" && (
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Введите пароль!" }]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder="Пароль"
                  disabled={isLoading}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={isLoading}
              >
                {state === "login" ? "Войти" : "Напомнить"}
              </Button>
            </Form.Item>
          </Form>
          <Button
            onClick={this.changeState}
            type="link"
            className="login-form-button"
          >
            {state === "login" ? "Забыли пароль?" : "Войти по логину и паролю"}
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.auth.message,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, { AUTH, SET_AUTH })(Login);
