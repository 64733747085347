import React, { Component, Fragment } from "react";
import { Typography, Spin } from "antd";
import { Modal, Divider } from "antd";
import Img from "react-image";
import { Audio, Map, Video } from "./Types";
import { config } from "@/constants";
import moment from "moment";
import { FileOutlined, CloseCircleTwoTone } from "@ant-design/icons";
import "./index.scss";

const { Text } = Typography;

export default class Message extends Component {
  state = { preview: false };
  handlePreview = (preview) => () => this.setState({ preview });
  text = (text) => text.split("\n").map((t, i) => <p key={i}>{t}</p>);
  render() {
    const { startsSequence, endsSequence, showTimestamp } = this.props;
    const { data, isMine, isAuthor, token } = this.props;
    const { preview } = this.state;

    const friendlyTimestamp = moment(data.createdAt).format("LLLL");
    let message = "";
    let previewModal;

    if (data.type === "text") {
      message = <Fragment>{this.text(data.text)}</Fragment>;
    } else {
      const url = config.baseURL + "file/" + data._id + "?token=" + token;
      let previewContent;

      if (data.type === "image") {
        message = (
          <Fragment>
            <Img
              onClick={this.handlePreview(true)}
              alt="image_preview"
              src={url}
              onLoad={this.props.scrollToBottom}
              loader={<Spin />}
              className="message__image"
            />
            {data.text === "Изображение" || this.text(data.text)}
          </Fragment>
        );
        previewContent = (
          <Img alt="image" style={{ width: "100%" }} src={url} />
        );
      } else if (data.type === "video") {
        message = previewContent = (
          <Fragment>
            <Video
              className="message__video"
              src={url}
              loop={false}
              autoplay={false}
            />
            {data.text === "Видео" || this.text(data.text)}
          </Fragment>
        );
      } else if (data.type === "voice" || data.type === "audio") {
        message = <Audio src={url} controls />;
      } else if (data.type === "location" && data.meta) {
        let { latitude, longitude } = data.meta;
        message = <Map {...{ latitude, longitude }} />;
      } else if (data.type === "document") {
        message = (
          <span
            onClick={() => window.open(url, "_blank")}
            className="message__file"
          >
            <FileOutlined /> Документ
          </span>
        );
      }
      if (previewContent)
        previewModal = (
          <Modal
            visible={preview}
            footer={null}
            closeIcon={<CloseCircleTwoTone style={{ fontSize: 20 }} />}
            onCancel={this.handlePreview(false)}
          >
            {previewContent}
          </Modal>
        );
    }
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`,
        ].join(" ")}
      >
        {showTimestamp && (
          <Divider className="timestamp">{friendlyTimestamp}</Divider>
        )}
        {startsSequence && isMine && (
          <div className={["author", `${isMine ? "mine" : ""}`].join(" ")}>
            <Text className="author__name">{data.user.name}</Text>
          </div>
        )}
        {previewModal}
        <div className={"bubble-container"}>
          <div
            className={["bubble", `${!isAuthor ? "not-author" : ""}`].join(" ")}
            title={friendlyTimestamp}
          >
            {message}
            <div className="message__date">
              {moment(data.createdAt).format("HH:mm")}
              {isMine && (
                <Fragment>
                  {data.status === "Sent" && (
                    <img
                      alt="sent"
                      src={require("@/assets/images/status/sent.svg")}
                    />
                  )}
                  {data.status === "Delivered" && (
                    <img
                      alt="delivered"
                      src={require("@/assets/images/status/delivered.svg")}
                    />
                  )}
                  {data.status === "Read" && (
                    <img
                      alt="read"
                      src={require("@/assets/images/status/read.svg")}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
