import React, { Component } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { call } from "@/actions/axios";
import { SET_APPS } from "@/actions/app";
import { Layout } from "antd";
import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
  userIsAuthenticated,
  userIsAdminRedir,
  userIsSuperAdminRedir,
} from "./auth";

import Chat from "./screens/Chat";
import Login from "./screens/Login";
import ChangePassword from "./screens/ChangePassword";
import Statistic from "./screens/Statistic";
import Admin from "./screens/Admin";
import Apps from "./screens/Apps";
import Replies from "./screens/Apps/Replies";
import Users from "./screens/Users";
import NotFound from "./screens/NotFound";

import { Sider as SIDER } from "./ui";
import { Socket as S } from "./ui";

import "antd/dist/antd.css";
import "./App.scss";

const Sider = userIsAuthenticated(() => <SIDER />);
const Socket = userIsAuthenticated(() => <S />);

const LoginView = userIsNotAuthenticatedRedir(Login);
const ChangePasswordView = userIsNotAuthenticatedRedir(ChangePassword);
const StatisticView = userIsAuthenticatedRedir(Statistic);
const ChatView = userIsAuthenticatedRedir(Chat);
const AppsView = userIsAdminRedir(Apps);
const RepliesView = userIsAdminRedir(Replies);
const UsersView = userIsAdminRedir(Users);

const AdminView = userIsSuperAdminRedir(Admin);

const { Content } = Layout;

class App extends Component {
  async componentDidMount() {
    const apps = [{ path: ["clientSelected"], value: null }];
    this.props.SET_APPS(apps);
  }
  render() {
    return (
      <BrowserRouter>
        <Layout className="main__wrapper">
          <Layout>
            <Sider />
            <Socket />
            <Content className="main__content__layout">
              <Switch>
                <Route exact path="/" component={ChatView} />
                <Route exact path="/apps" component={AppsView} />
                <Route exact path="/apps/replies/:id" component={RepliesView} />
                <Route exact path="/users" component={UsersView} />
                <Route exact path="/statistic" component={StatisticView} />
                <Route exact path="/admin" component={AdminView} />
                <Route path="/login" component={LoginView} />
                <Route path="/change_password" component={ChangePasswordView} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default connect(null, { call, SET_APPS })(App);
