import React from "react";
import moment from "moment";
import { Avatar } from "antd";

var formats = {
  sameDay: "[Сегодня], HH:mm",
  lastDay: "[Вчера], HH:mm",
  lastWeek: "DD MMMM YYYY",
  sameElse: "DD MMMM YYYY",
};

export const formatDate = (date) => {
  const today = new Date();
  return moment(date).calendar(today, formats);
};

export const avatarReplacer = (client, size) => {
  if (client.avatar) return <Avatar size={size} src={client.avatar} />;
  return <Avatar size={size} src={require("@/assets/images/empty-user.svg")} />;
};
