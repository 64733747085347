import React from "react";
import { Spin } from "antd";

const Loading = () => (
  <div className="app__overlay">
    <div className="app__loading">{<Spin />}</div>
  </div>
);

export default Loading;
