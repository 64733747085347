import React, { Component, Fragment } from "react";
import { Card, PageHeader } from "antd";
import uniqid from "uniqid";
import _ from "lodash";
import { Row, Col, Input, Button, Typography } from "antd";
import { Table, Popconfirm, Form, Switch } from "antd";
import { Modal, message } from "antd";
import { connect } from "react-redux";
import { call } from "@/actions/axios";
import { SET_APP_BY_PARAM } from "@/actions/app";
import { PlusOutlined } from "@ant-design/icons";
import "./index.scss";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const { Text } = Typography;
const { TextArea } = Input;

class Replies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      app: null,
      preview: null,
      adding: false,
      loading: true,
      fields: [],
    };
  }
  onSave = () => this.form.submit();
  onFinish = async () => {
    const { status, ...values } = this.form.getFieldsValue();
    const { fields } = this.state;
    if (fields.length === 0)
      return message.info("Добавьте необходимый список команд");

    await this.onUpdate({
      status,
      replies: {
        ...values,
        fields,
      },
    });
    message.success("Авто-ответы сохранены");
  };
  onUpdate = async (values) => {
    this.setState({ loading: true });
    const { id } = this.props.match.params;
    const app = await this.props.call({
      url: "apps/" + id,
      method: "PATCH",
      data: values,
    });
    this.setState({ loading: false });
    this.props.SET_APP_BY_PARAM(["apps"], ["_id", id], app.data);
  };
  columns = () => [
    {
      title: "Команда",
      dataIndex: "command",
      width: 100,
      type: "input",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            this.command.setFieldsValue({ ...record });
            this.setState({ adding: true });
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Описание",
      dataIndex: "description",
      type: "input",
    },
    {
      title: "Текст",
      dataIndex: "text",
      type: "text",
    },
    {
      title: "",
      width: 100,
      render: (_, record) => (
        <Popconfirm
          title="Уверены, что хотите удалить команду?"
          onConfirm={() => this.handleDelete(record.key)}
        >
          <Button type="link">Удалить</Button>
        </Popconfirm>
      ),
    },
  ];
  onAdd = () => {
    this.command.resetFields();
    this.setState({ adding: true });
  };
  handleDelete = (key) => {
    const fields = [...this.state.fields];
    this.setState({ fields: fields.filter((item) => item.key !== key) }, () => {
      this.renderPreview();
    });
  };
  onFieldsChange = () => this.renderPreview();
  componentDidMount() {
    const { id } = this.props.match.params;
    const { apps } = this.props;
    const app = _.find(apps, { _id: id });
    if (!app) this.props.history.replace("/apps");

    let values = {};
    let fields = [];
    if (app.replies && _.isObject(app.replies)) {
      const { fields: f, ...rest } = app.replies;
      fields = f;
      values = { ...rest };
    }
    this.setState({ app });
    this.timer = setInterval(() => {
      if (this.form) {
        clearInterval(this.timer);
        const state = { loading: false };
        if (fields.length > 0) state.fields = fields;
        this.setState(state);
        if (!_.isEmpty(values)) this.form.setFieldsValue(values);
        this.renderPreview();
      }
    }, 500);
  }
  handleOk = () => this.command.submit();
  onAddFinish = (values) => {
    const fields = [...this.state.fields];
    if (values.key) {
      const fieldIndex = _.findIndex(fields, { key: values.key });
      fields[fieldIndex] = {
        ...values,
        description: values.description.toLowerCase(),
      };
    } else {
      const key = uniqid.time();
      fields.push({ ...values, key });
    }
    this.setState({ fields, adding: false }, () => {
      this.renderPreview();
    });
  };
  handleClose = () => this.setState({ adding: false });
  renderPreview = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      try {
        const { welcome, header, operator } = this.form.getFieldsValue();
        const { fields } = this.state;
        let preview;
        if (!welcome)
          preview = (
            <Text>Для предпросмотра необходимо заполнить поле приветствия</Text>
          );
        else if (!header)
          preview = (
            <Text>Для предпросмотра необходимо заполнить поле текста</Text>
          );
        else if (!fields.length)
          preview = (
            <Text>Для предпросмотра необходимо добавить список комманд</Text>
          );
        else if (!operator)
          preview = (
            <Text>
              Для предпросмотра необходимо добавить команду для вызова оператора
            </Text>
          );
        else
          preview = (
            <Text style={{ whiteSpace: "pre-wrap" }}>
              {welcome}
              {"\n"}
              {header}
              {"\n"}
              {fields
                .map(({ command, description }) => {
                  return `${command} - ${description.toLowerCase()}`;
                })
                .join("\n")}
              {`${operator} - связать с оператором`}
            </Text>
          );
        this.setState({ preview });
        clearTimeout(this.timer);
      } catch (e) {
        console.log("not found form");
        clearTimeout(this.timer);
      }
    }, 1000);
  };
  render() {
    const { loading, adding, preview, app } = this.state;
    const { fields } = this.state;
    if (!app) return null;
    return (
      <Fragment>
        <PageHeader
          ghost={false}
          onBack={() => this.props.history.replace("/apps")}
          title={app.name}
          subTitle="Настройте авто-ответы для более эффективной обработки обращений"
          extra={[
            <Button
              type="primary"
              key="save"
              shape="round"
              loading={loading}
              onClick={this.onSave}
            >
              Сохранить
            </Button>,
          ]}
        />
        <div className="page__container">
          <Card bordered={false}>
            <Row>
              <Col span={18}>
                <Form
                  ref={(f) => (this.form = f)}
                  onFieldsChange={this.onFieldsChange}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 14 }}
                  layout="horizontal"
                  onFinish={this.onFinish}
                >
                  <Form.Item
                    name="enabled"
                    label="Активно"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Поле обязательно`,
                      },
                    ]}
                    name="welcome"
                    label="Приветствие"
                  >
                    <Input.TextArea
                      disabled={loading}
                      placeholder="Введите текст"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Поле обязательно`,
                      },
                    ]}
                    name="header"
                    label="Основной текст"
                  >
                    <Input.TextArea
                      disabled={loading}
                      placeholder="Введите текст"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Поле Команда обязательно`,
                      },
                    ]}
                    name="operator"
                    label="Команда для вызова оператора"
                  >
                    <Input disabled={loading} placeholder="Введите текст" />
                  </Form.Item>
                </Form>
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.onAdd}
                  disabled={loading}
                  style={{ marginBottom: 16 }}
                >
                  <PlusOutlined /> Добавить команду
                </Button>
                <Table
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={fields}
                  disabled={loading}
                  rowSelection={this.select}
                  pagination={false}
                  columns={this.columns()}
                />
              </Col>
              <Col span={6}>
                <div className="chatbot__preview__title">Предпросмотр</div>
                <div className="chatbot__preview">{preview}</div>
              </Col>
            </Row>
            <Modal
              title="Команда"
              visible={adding}
              onOk={this.handleOk}
              okText="Сохранить"
              onCancel={this.handleClose}
              forceRender={true}
            >
              <Form
                {...layout}
                ref={(f) => (this.command = f)}
                onFinish={this.onAddFinish}
              >
                <Form.Item name="key" style={{ display: "none" }}>
                  <Input />
                </Form.Item>
                {this.columns()
                  .filter((column) => column.dataIndex)
                  .map((column) => (
                    <Form.Item
                      key={column.dataIndex}
                      label={column.title}
                      name={column.dataIndex}
                      rules={[
                        {
                          required: true,
                          message: `Поле ${column.title} обязательно`,
                        },
                      ]}
                    >
                      {column.type === "input" ? <Input /> : <TextArea />}
                    </Form.Item>
                  ))}
              </Form>
            </Modal>
          </Card>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  apps: state.app.apps,
});

export default connect(mapStateToProps, { call, SET_APP_BY_PARAM })(Replies);
