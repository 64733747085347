import React, { Component } from "react";
import { Loading } from "@/ui";
import "./index.scss";

export default class NotFound extends Component {
  componentDidMount() {
    this.props.history.push("/");
  }
  render() {
    return <Loading />;
  }
}
