import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import io from "socket.io-client";
import _ from "lodash";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  SET_APP_BY_PARAM,
  REMOVE_APP_BY_PARAM,
  SET_APP,
  UNSHIFT_APP,
  PUSH_APP,
  GET_CLIENTS,
  CLIENTS_NOTIFICATION,
  GET_NOTIFICATION,
  MARK_AS_READ,
} from "@/actions/app";
import { config } from "../constants";

class Socket extends Component {
  componentWillUnmount() {
    this.socket.disconnect();
  }
  componentDidMount() {
    const { user } = this.props;
    const query = queryString.stringify({
      user_id: user._id,
    });
    this.socket = io(config.baseURL, {
      query,
      transports: ["websocket", "polling"],
    });
    this.socket.on("client", ({ mode, client }) => {
      const { location, clientType } = this.props;
      if (client.user === user._id) {
        if (mode === "update") {
          this.props.SET_APP_BY_PARAM(
            ["clients", "data"],
            ["_id", client._id],
            client
          );
          return this.props.CLIENTS_NOTIFICATION("my");
        }
      }

      if (location.pathname === "/" && clientType === "all") {
        if (mode === "assign") {
          this.props.SET_APP(["clientSelected"], null);
          this.props.REMOVE_APP_BY_PARAM(
            ["clients", "data"],
            "_id",
            client._id
          );
        }
      }
    });
    this.socket.on("clients-update", () => {
      const { location, clientType } = this.props;
      if (location.pathname === "/" && clientType === "all") {
        this.props.GET_CLIENTS();
      }
    });
    this.socket.on("message-update", ({ message }) => {
      const { location, clientSelected } = this.props;
      if (
        location.pathname === "/" &&
        clientSelected &&
        clientSelected._id === message.client
      ) {
        this.props.SET_APP_BY_PARAM(
          ["messages", "data"],
          ["_id", message._id],
          message
        );
      }
    });
    this.socket.on("new-message", async ({ message, client }) => {
      const { location, clientSelected, clientType } = this.props;
      if (clientSelected && clientSelected._id === message.client) {
        if (client.user) {
          client.unread = 0;
          this.props.MARK_AS_READ();
        }
        this.props.SET_APP(["clientSelected"], client);
        this.props.UNSHIFT_APP(["messages", "data"], message);
      }
      if (location.pathname === "/") {
        const { clients } = this.props;
        const exists = _.find(clients.data, { _id: client._id });
        if (client.user && clientType === "my" && client.user === user._id) {
          if (exists) {
            this.props.SET_APP_BY_PARAM(
              ["clients", "data"],
              ["_id", client._id],
              client
            );
          } else this.props.UNSHIFT_APP(["clients", "data"], client);
          this.props.CLIENTS_NOTIFICATION("my");
          this.props.GET_NOTIFICATION("all");
        } else if (!client.user && clientType === "all") {
          if (exists) {
            this.props.SET_APP_BY_PARAM(
              ["clients", "data"],
              ["_id", client._id],
              client
            );
          } else this.props.UNSHIFT_APP(["clients", "data"], client);
          this.props.CLIENTS_NOTIFICATION("all");
          this.props.GET_NOTIFICATION("my");
        }
      }
    });
  }
  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => ({
  clients: state.app.clients,
  clientType: state.app.clientType,
  user: state.auth.user,
  clientSelected: state.app.clientSelected,
});

export default withRouter(
  connect(mapStateToProps, {
    SET_APP_BY_PARAM,
    REMOVE_APP_BY_PARAM,
    SET_APP,
    UNSHIFT_APP,
    PUSH_APP,
    GET_CLIENTS,
    CLIENTS_NOTIFICATION,
    GET_NOTIFICATION,
    MARK_AS_READ,
  })(Socket)
);
