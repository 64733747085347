import * as types from "@/constants/types";
import Immutable from "immutable";
import _ from "lodash";

const INITIAL_STATE = {
  isLoading: false,
  user: null,
  token: null,
  isAdmin: false,
  isGod: false,
};

export default (state = INITIAL_STATE, data) => {
  var newState;
  switch (data.type) {
    case types.AUTH:
      if (data.action === types.REQUEST) {
        return { ...state, isLoading: true };
      } else if (data.action === types.REFRESH) {
        return { ...state, isLoading: false };
      } else if (data.action === types.SUCCESS) {
        var params = {
          ...state,
          ...checkRole({ ...data.data }),
          isLoading: true,
        };
        return params;
      } else {
        return { ...state, message: data.message, isLoading: false };
      }
    case types.LOGOUT:
      return { ...INITIAL_STATE };
    case types.SET_AUTH:
      newState = Immutable.fromJS(state).setIn(data.path, data.value);
      return newState.toJS();
    default:
      return state;
  }
};

//Проверка на наличие ролей у пользователя при авторизации.
//Влияет на интерфейс и функционал
const checkRole = (data) => {
  if (!_.isArray(data.user.roles)) return data;
  const assignRoles = {};
  data.user.roles.forEach((role) => {
    if (role.type === "admin") assignRoles.isAdmin = true;
    else if (role.type === "super-admin") assignRoles.isSuperAdmin = true;
    else if (role.type === "superviser") assignRoles.isSuperviser = true;
  });

  if (!_.isEmpty(assignRoles)) {
    Object.keys(assignRoles).forEach((assign) => {
      data.user[assign] = true;
    });
  } else data.user.isOperator = true;
  return data;
};
