import { axios } from "./axios";
import * as types from "@/constants/types";
import createAppStore from "@/store";

export const SET_AUTH = (path, value) => ({
  type: types.SET_AUTH,
  path,
  value,
});

export const LOGOUT = () => {
  return (dispatch) => {
    const { persistor } = createAppStore();
    localStorage.clear();
    persistor.purge();
    dispatch({ type: types.LOGOUT });
  };
};

export const AUTH = (url, params, callback = true) => (dispatch) => {
  dispatch({ type: types.AUTH, action: types.REQUEST });

  return axios
    .post(url, params)
    .then(async (response) => {
      if (callback && response.status === 200) {
        const { token } = response.data;
        await ME(token)(dispatch);
      } else {
        dispatch({ type: types.AUTH, action: types.REFRESH });
      }
      return response;
    })
    .catch((e) => {
      if (e.response) {
        const { message } = e.response.data;
        dispatch({ type: types.AUTH, action: types.FAILURE, message });
      }
    });
};

export const ME = (token) => (dispatch) => {
  return axios
    .get("users/me", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      if (response.status === 200) {
        const { data } = response;
        await dispatch({
          type: types.AUTH,
          action: types.SUCCESS,
          data: { token, user: data },
        });
      }

      return response;
    });
};
