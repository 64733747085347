import * as types from "@/constants/types";
import _ from "lodash";
import queryString from "query-string";
import { call } from "./axios";

export const SET_APP = (path, value) => ({ type: types.SET_APP, path, value });
export const SET_APP_BY_PARAM = (path, param, value) => ({
  type: types.SET_APP_BY_PARAM,
  path,
  param,
  value,
});
export const SET_APPS = (arr) => ({ type: types.SET_APPS, arr });
export const PUSH_APP = (path, value) => ({
  type: types.PUSH_APP,
  path,
  value,
});
export const UNSHIFT_APP = (path, value) => ({
  type: types.UNSHIFT_APP,
  path,
  value,
});
export const REMOVE_APP_BY_PARAM = (path, key, value) => ({
  type: types.REMOVE_APP_BY_PARAM,
  path,
  key,
  value,
});

export const GET_CLIENTS = () => async (dispatch, getState) => {
  let arr = [
    { path: ["clients"], value: { data: [] } },
    { path: ["clientsLoading"], value: true },
  ];
  dispatch(SET_APPS(arr));
  let { clientType, search } = getState().app;
  const query = queryString.stringify({ type: clientType, search });
  const { data } = await call({
    url: "clients/?" + query,
    method: "GET",
  })(dispatch, getState);
  // const clients = _.reverse(_.sortBy(data.data, ["updatedAt"]));
  arr = [
    { path: ["clients"], value: data },
    { path: ["clientsLoading"], value: false },
  ];
  dispatch(SET_APPS(arr));
  dispatch(CLIENTS_NOTIFICATION(clientType));
  if (clientType === "my") dispatch(GET_NOTIFICATION("all"));
  else if (clientType === "all") dispatch(GET_NOTIFICATION("my"));
  else dispatch(GET_NOTIFICATION_ALL());
};

export const GET_CLIENTS_MORE = (page) => async (dispatch, getState) => {
  dispatch(SET_APP(["clientsLoading"], true));
  let { clientType, clients } = getState().app;
  const query = queryString.stringify({ type: clientType, page });
  const { data } = await call({
    url: "clients/?" + query,
    method: "GET",
  })(dispatch, getState);

  const _data = _.uniqBy([...clients.data, ...data.data], "_id");
  data.data = _data;

  const arr = [
    { path: ["clients"], value: data },
    { path: ["clientsLoading"], value: false },
  ];
  dispatch(SET_APPS(arr));
};

export const GET_MESSAGES = () => async (dispatch, getState) => {
  let arr = [
    { path: ["messages"], value: { data: [] } },
    { path: ["messagesLoading"], value: true },
  ];
  dispatch(SET_APPS(arr));
  let { clientSelected: client } = getState().app;
  dispatch(SET_APP(["messagesLoading"], true));
  let url = "messages/" + client._id;
  const { data } = await call({
    url,
    method: "GET",
  })(dispatch, getState);
  arr = [
    { path: ["messages"], value: data },
    { path: ["messagesLoading"], value: false },
  ];
  dispatch(SET_APPS(arr));
};

export const GET_MESSAGES_MORE = (page) => async (dispatch, getState) => {
  dispatch(SET_APP(["messagesLoading"], true));
  let { clientSelected: client, messages } = getState().app;
  let url = "messages/" + client._id;
  if (page) url += "?page=" + page;
  const { data } = await call({
    url,
    method: "GET",
  })(dispatch, getState);

  const _data = _.uniqBy([...messages.data, ...data.data], "_id");
  data.data = _data;

  const arr = [
    { path: ["messages"], value: data },
    { path: ["messagesLoading"], value: false },
  ];
  dispatch(SET_APPS(arr));
};

export const MARK_AS_READ = () => async (dispatch, getState) => {
  const { clientSelected } = getState().app;
  const client = await call({
    url: "clients/" + clientSelected._id,
    method: "PATCH",
    data: { unread: true },
  })(dispatch, getState);
  dispatch(SET_APP(["clientSelected"], client.data));
};

export const ASSIGN = () => async (dispatch, getState) => {
  const { clientSelected } = getState().app;
  const client = await call({
    url: "clients/" + clientSelected._id,
    method: "PATCH",
    data: { assign: true },
  })(dispatch, getState);
  const arr = [
    { path: ["clientSelected"], value: client.data },
    { path: ["clientType"], value: "my" },
  ];
  dispatch(SET_APPS(arr));
  GET_CLIENTS()(dispatch, getState);
};

export const FINISH = () => async (dispatch, getState) => {
  const { clientSelected } = getState().app;
  await call({
    url: "clients/" + clientSelected._id,
    method: "PATCH",
    data: { finish: true },
  })(dispatch, getState);
  dispatch(SET_APP(["clientSelected"], null));
  dispatch(REMOVE_APP_BY_PARAM(["clients", "data"], "_id", clientSelected._id));
};

export const BLOCK = () => async (dispatch, getState) => {
  const { clientSelected } = getState().app;
  await call({
    url: "clients/" + clientSelected._id,
    method: "PATCH",
    data: { block: true },
  })(dispatch, getState);
  dispatch(SET_APP(["clientSelected"], null));
  dispatch(REMOVE_APP_BY_PARAM(["clients", "data"], "_id", clientSelected._id));
};

export const CLIENTS_NOTIFICATION = (type) => async (dispatch, getState) => {
  const { clients } = getState().app;
  const count = _.countBy(clients.data, (c) => c.unread > 0);
  dispatch(SET_APP([type + "Messages"], count.true));
};

export const GET_NOTIFICATION = (type) => async (dispatch, getState) => {
  let url = `clients/notifications/get?type=${type}`;
  const { data } = await call({
    url,
    method: "GET",
  })(dispatch, getState);
  dispatch(SET_APP([type + "Messages"], data));
};

export const GET_NOTIFICATION_ALL = () => async (dispatch, getState) => {
  const { data } = await call({
    url: "clients/notifications/get",
    method: "GET",
  })(dispatch, getState);
  const arr = Object.keys(data).map((key) => ({
    path: [key],
    value: data[key],
  }));
  dispatch(SET_APPS(arr));
};
