import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import connectedAuthWrapper from "redux-auth-wrapper/connectedAuthWrapper";

import Loading from "@/ui/Loading";

const locationHelper = locationHelperBuilder({});

const userIsAuthenticatedDefaults = {
  authenticatedSelector: (state) => state.auth.user !== null,
  authenticatingSelector: (state) => state.auth.isLoading,
  wrapperDisplayName: "UserIsAuthenticated",
};

export const userIsAuthenticated = connectedAuthWrapper(
  userIsAuthenticatedDefaults
);

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: Loading,
  redirectPath: "/login",
});

const userIsNotAuthenticatedDefaults = {
  authenticatedSelector: (state) =>
    state.auth.user === null && state.auth.isLoading === false,
  wrapperDisplayName: "UserIsNotAuthenticated",
};

export const userIsAdminRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    state.auth.user !== null &&
    (state.auth.user.isAdmin || state.auth.user.isSuperviser),
  predicate: (user) => user.isAdmin || user.isSuperviser,
  wrapperDisplayName: "UserIsADmin",
  redirectPath: "/",
  allowRedirectBack: false,
});

export const userIsSuperAdminRedir = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    state.auth.user !== null && state.auth.user.isSuperAdmin,
  predicate: (user) => user.isSuperAdmin,
  wrapperDisplayName: "UserIsSuperADmin",
  redirectPath: "/",
  allowRedirectBack: false,
});

export const userIsNotAuthenticated = connectedAuthWrapper(
  userIsNotAuthenticatedDefaults
);

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/",
  allowRedirectBack: false,
});
