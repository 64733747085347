import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import Message from "./Message";

class Messages extends Component {
  renderMessage = (current, i) => {
    const { messages, user, token } = this.props;
    let previous = messages[i - 1];
    let next = messages[i + 1];
    let isMine = !_.isEmpty(current.user);
    let isAuthor = isMine && current.user._id === user._id;
    let currentMoment = moment(current.createdAt);
    let prevBySameAuthor = false;
    let nextBySameAuthor = false;
    let startsSequence = true;
    let endsSequence = true;
    let showTimestamp = true;

    if (previous) {
      let previousMoment = moment(previous.createdAt);
      let previousDuration = moment.duration(
        previousMoment.diff(currentMoment)
      );
      if (isMine && previous.user && previous.user._id === current.user._id) {
        prevBySameAuthor = true;
      } else if (!isMine && previous.client === current.client) {
        prevBySameAuthor = true;
      }

      if (prevBySameAuthor && previousDuration.as("hours") < 1) {
        startsSequence = false;
      }

      if (previousDuration.as("hours") < 1) {
        showTimestamp = false;
      }
    }

    if (next) {
      let nextMoment = moment(next.createdAt);
      let nextDuration = moment.duration(nextMoment.diff(currentMoment));
      if (isMine && next.user && next.user._id === current.user._id) {
        nextBySameAuthor = true;
      } else if (!isMine && !next.user && next.client === current.client) {
        nextBySameAuthor = true;
      }

      if (nextBySameAuthor && nextDuration.as("hours") < 1) {
        endsSequence = false;
      }
    }
    return (
      <Message
        key={i}
        isMine={isMine}
        isAuthor={isAuthor}
        startsSequence={startsSequence}
        endsSequence={endsSequence}
        showTimestamp={showTimestamp}
        data={current}
        scrollToBottom={this.scrollToBottom}
        token={token}
      />
    );
  };
  render() {
    const { messages } = this.props;
    return _.reverse(messages.map(this.renderMessage));
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
});

Messages.defaultProps = {
  messages: [],
};

export default connect(mapStateToProps)(Messages);
