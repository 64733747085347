import React, { Component, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Input, Card, Button, Empty, Form } from "antd";
import { Popover, Upload, message, Menu } from "antd";
import { Spin, Dropdown, Layout } from "antd";
import List from "./List";
import Profile from "./Profile";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { call } from "@/actions/axios";
import { SET_APP, ASSIGN, FINISH, UNSHIFT_APP } from "@/actions/app";
import { GET_MESSAGES, GET_MESSAGES_MORE, BLOCK } from "@/actions/app";
import { Loading } from "@/ui";
import { connect } from "react-redux";
import { config } from "@/constants";
import Messages from "./Messages";
import Map from "./Map";
import {
  SmileOutlined,
  FileAddOutlined,
  AudioOutlined,
  FileImageOutlined,
  PaperClipOutlined,
  VideoCameraOutlined,
  LoadingOutlined,
  AimOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Chat extends Component {
  state = {
    text: null,
    pending: false,
    modal: "",
    ready: false,
  };
  static List = List;

  renderAssign = (assigned) => {
    if (assigned) return "Завершить";
    return "Взять в задачу";
  };
  onFinish = async () => {
    const text = this.form.getFieldValue("text");
    if (!text) return;
    this.send({ type: "text", message: text });
    this.form.resetFields();
  };
  send = async (params) => {
    this.setState({ pending: true });
    const { selected } = this.props;
    try {
      const message = await this.props.call({
        url: "clients/" + selected._id + "/send",
        method: "POST",
        data: {
          ...params,
        },
      });
      this.props.UNSHIFT_APP(["messages", "data"], message.data);
      await this.setState({ pending: false });
      this.scrollToBottom();
    } catch (e) {
      this.setState({ pending: false });
      message.error("Не удалось отправить сообщение. Попробуйте еще раз!");
    }
  };
  keySubmit = ({ nativeEvent: e }) => {
    const enterKey = e.keyCode === 13 || e.keyCode === 10;
    console.log(enterKey, e.shiftKey, window.macKeys);
    if (enterKey && e.shiftKey) {
    } else if (enterKey) {
      this.onFinish();
    }
  };

  componentDidUpdate(props) {
    if (
      props.messages.data.length === 0 &&
      this.props.messages.data !== 0 &&
      this.props.loading !== props.loading
    ) {
      this.scrollToBottom();
    }
  }
  scrollToBottom = () => {
    const chat = document.getElementsByClassName("chat__message__list")[0];
    if (chat) chat.scrollTo(0, chat.scrollHeight);
  };
  emojiSelect = (e) => {
    let text = this.form.getFieldValue("text");
    const ta = this.input.resizableTextArea.textArea;
    const start = ta.selectionStart;
    const end = ta.selectionEnd;
    if (text)
      text =
        text.substr(0, start) +
        e.native +
        text.substr(start, end) +
        text.substr(end, text.length);
    else text = e.native;

    this.form.setFieldsValue({ text });
  };
  emoji = (e) => {
    e.preventDefault();
  };
  upload = (type, accept) => {
    const { token, selected } = this.props;
    return {
      name: "file",
      showUploadList: false,
      action: config.baseURL + "clients/" + selected._id + "/send",
      method: "POST",
      accept,
      data: { type },
      headers: {
        authorization: "Bearer " + token,
      },
      onChange: async (info) => {
        if (info.file.status === "uploading") {
          this.setState({ pending: true });
          console.log("uploading");
        }
        if (info.file.status === "done") {
          this.setState({ pending: false });
          const data = info.file.response;
          await this.props.UNSHIFT_APP(["messages", "data"], data);
          message.success(`${info.file.name} файл отправлен`);
        } else if (info.file.status === "error") {
          this.setState({ pending: false });
          console.log(info);
          message.error(`Ошибка при отправке файла ${info.file.name}.`);
        }
      },
    };
  };
  menu = () => {
    const items = [
      {
        type: "image",
        text: "Отправить изображение",
        accept: ".jpeg,.jpg,.png",
        icon: <FileImageOutlined />,
      },
      {
        type: "video",
        text: "Отправить видео",
        accept: ".mp4,.3gp,.3g2",
        icon: <VideoCameraOutlined />,
      },
      {
        type: "audio",
        text: "Отправить аудио",
        accept: ".acc,.mp4,.mp4a,.amr,.mpeg,.ogg",
        icon: <AudioOutlined />,
      },
      {
        type: "document",
        text: "Отправить документ",
        accept: ".pdf,.doc,.docx,.xl,.ppt,.txt",
        icon: <FileAddOutlined />,
      },
    ];
    return (
      <Menu>
        {items.map((item) => (
          <Menu.Item key={item.type}>
            <Upload {...this.upload(item.type, item.accept)}>
              {item.icon} {item.text}
            </Upload>
          </Menu.Item>
        ))}
        <Menu.Item key="geo" onClick={this.map}>
          <AimOutlined />
          Отправить геолокацию
        </Menu.Item>
      </Menu>
    );
  };
  map = () => this.setState({ modal: "Map" });
  coords = async (values) => {
    await this.send({ type: "location", message: values });
  };
  assign = () => {
    const { selected } = this.props;
    if (!selected.user) this.props.ASSIGN();
    else this.props.FINISH();
  };
  clientMenu = () => (
    <Menu>
      <Menu.Item onClick={this.block}>Заблокировать</Menu.Item>
    </Menu>
  );
  block = () => {
    const { selected } = this.props;
    if (selected && selected.user) this.props.BLOCK();
  };
  onLoad = (page) => this.props.GET_MESSAGES_MORE(page);
  render() {
    const { selected } = this.props;

    if (!selected)
      return (
        <Empty
          image={require("@/assets/images/not-selected.png")}
          imageStyle={{
            marginTop: 50,
            height: 200,
          }}
          description={
            <Fragment>
              <br />
              <span className="title">
                Начните отвечать на сообщение клиентов
              </span>
              <br />
              <span>Выберите из списка слева клиента</span>
            </Fragment>
          }
        ></Empty>
      );
    const { user, loading, messages, type } = this.props;
    const { pending, modal } = this.state;
    const assigned = !!selected.user;
    const isManager = assigned && selected.user === user._id;
    const disabled = !isManager || pending || loading;
    return (
      <Layout>
        <Content style={{ overflow: "hidden" }}>
          {modal === "Map" && (
            <Map
              onCancel={() => this.setState({ modal: "" })}
              onSend={this.coords}
            />
          )}
          <Card
            className="chat"
            bordered={false}
            title={selected.name}
            extra={
              (!assigned || isManager) &&
              type !== "archive" &&
              selected &&
              selected.status && (
                <Fragment>
                  <Button type="primary" shape="round" onClick={this.assign}>
                    {this.renderAssign(assigned)}
                  </Button>
                  <Dropdown
                    disabled={!assigned}
                    overlay={this.clientMenu()}
                    placement="bottomLeft"
                  >
                    <Button type="link">
                      <EllipsisOutlined style={{ fontSize: 16 }} />
                    </Button>
                  </Dropdown>
                </Fragment>
              )
            }
          >
            <div
              className={
                "chat__message__list " +
                (type !== "archive" && selected && selected.status
                  ? ""
                  : "bottom__hidden")
              }
              ref={(el) => (this.el = el)}
            >
              {loading && messages.data.length === 0 ? (
                <Loading />
              ) : (
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={1}
                  isReverse={true}
                  loadMore={this.onLoad}
                  hasMore={!loading && messages.hasNextPage}
                  useWindow={false}
                >
                  <Messages messages={messages.data} />
                </InfiniteScroll>
              )}
            </div>
            {type !== "archive" && selected && selected.status && (
              <div className="chat__bottom">
                <div className="button__groups">
                  <Button
                    size="small"
                    type="primary"
                    shape="round"
                    className="small__buttons"
                  >
                    Чаты
                  </Button>
                </div>
                <Form ref={(f) => (this.form = f)} onFinish={this.onFinish}>
                  <Form.Item name="text">
                    <Input.TextArea
                      size="large"
                      placeholder="Введите сообщение"
                      rows={1}
                      disabled={disabled}
                      onKeyDown={this.keySubmit}
                      ref={(i) => (this.input = i)}
                    />
                  </Form.Item>
                </Form>
                <div className="chat__buttom__buttons__container">
                  <div className="chat__bottom__buttons">
                    <Dropdown
                      disabled={disabled}
                      overlay={this.menu()}
                      placement="topCenter"
                    >
                      <Button type="link" disabled={disabled}>
                        <PaperClipOutlined />
                      </Button>
                    </Dropdown>
                    <Popover
                      content={
                        <Picker
                          showPreview={false}
                          showSkinTones={false}
                          emojiSize={18}
                          sheetSize={20}
                          onSelect={this.emojiSelect}
                        />
                      }
                      trigger="click"
                    >
                      <Button
                        type="link"
                        className="middle__button"
                        disabled={disabled}
                        onMouseDown={this.emoji}
                      >
                        <SmileOutlined />
                      </Button>
                    </Popover>
                    <Button
                      onClick={this.onFinish}
                      shape="circle"
                      disabled={disabled}
                      className="send__button"
                    >
                      {pending ? (
                        <Spin
                          indicator={antIcon}
                          style={{ color: "white" }}
                          size="small"
                        />
                      ) : (
                        <img
                          alt="send"
                          src={require("@/assets/images/send.svg")}
                          style={{ width: 16 }}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </Content>
        <Profile />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  messages: state.app.messages,
  selected: state.app.clientSelected,
  type: state.app.clientType,
  loading: state.app.messagesLoading,
});

export default connect(mapStateToProps, {
  call,
  SET_APP,
  ASSIGN,
  FINISH,
  BLOCK,
  UNSHIFT_APP,
  GET_MESSAGES,
  GET_MESSAGES_MORE,
})(Chat);
