import React, { Component, Fragment } from "react";
import { Table, Card, Modal, PageHeader } from "antd";
import { Form, Tag, Input, Button } from "antd";
import { Alert, Select, Switch } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import { call } from "@/actions/axios";
import {
  SET_APPS,
  PUSH_APP,
  SET_APP_BY_PARAM,
  REMOVE_APP_BY_PARAM,
} from "@/actions/app";
import "./index.scss";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const validateMessages = {
  required: "Данное поле обязательно!",
  types: {
    email: "Некорректный формат!",
  },
};

class Users extends Component {
  state = {
    loading: false,
    updateUser: null,
    message: null,
  };
  async componentDidMount() {
    this.setState({ loading: true });
    const apps = await this.props.call({
      url: "apps",
      method: "GET",
    });
    const users = await this.props.call({
      url: "users",
      method: "GET",
    });
    const arr = [
      { path: ["apps"], value: apps.data },
      { path: ["users"], value: users.data },
    ];
    this.props.SET_APPS(arr);
    this.setState({ loading: false });
  }
  columns = () => [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Администратор",
      dataIndex: "isAdmin",
      key: "isAdmin",
      render: (__, user) => {
        let isAdmin = _.find(user.roles, { type: "admin" });
        let isSuperviser = _.find(user.roles, { type: "superviser" });
        if (isAdmin) return <Tag color="success">Администратор</Tag>;
        else if (isSuperviser) return <Tag color="processing">Супервайзер</Tag>;
        return <Tag>Оператор</Tag>;
      },
    },
    {
      title: "Доступы",
      dataIndex: "apps",
      key: "apps",
      render: (apps) => apps.map((app) => <Tag key={app._id}>{app.name}</Tag>),
    },
    {
      title: "Действие",
      key: "action",
      render: (__, record) => {
        const { user } = this.props;
        return (
          <span>
            <Button type="link" onClick={this.updateUser(record)}>
              Редактировать
            </Button>
            {user._id !== record._id && (
              <Fragment>
                <span style={{ margin: "0 5px" }}>|</span>
                <Button type="link" onClick={this.deleteUser(record._id)}>
                  Удалить
                </Button>
              </Fragment>
            )}
          </span>
        );
      },
    },
  ];
  deleteUser = (id) => async () => {
    this.setState({ loading: true });
    await this.props.call({ url: "users/" + id, method: "DELETE" });
    this.props.REMOVE_APP_BY_PARAM(["users"], "_id", id);
    this.setState({ loading: false });
  };
  onFinish = async (values) => {
    this.setState({ loading: true, message: null });
    const { updateUser } = this.state;
    let params = { data: values };
    let user;
    try {
      if (updateUser) {
        user = await this.props.call({
          url: "users/" + updateUser._id,
          method: "PATCH",
          ...params,
        });
        this.props.SET_APP_BY_PARAM(
          ["users"],
          ["_id", updateUser._id],
          user.data
        );
      } else {
        user = await this.props.call({
          url: "users/create",
          method: "POST",
          ...params,
        });
        this.props.PUSH_APP(["users"], user.data);
      }
    } catch (e) {
      const { message } = e.response.data;
      return this.setState({ loading: false, message });
    }

    this.setState({ loading: false, isUser: false, updateUser: null });
  };
  handleOk = () => {
    this.form.submit();
  };
  handleClose = () => {
    this.form.resetFields();
    this.setState({ isUser: false, updateUser: null });
  };
  newUser = () => {
    this.form.resetFields();
    this.setState({ isUser: true, updateUser: null });
    const { user } = this.props;
    const apps = user.apps.map((app) => app._id);
    if (apps.length < 2) this.form.setFieldsValue({ apps });
  };
  updateUser = (updateUser) => () => {
    this.setState({ isUser: true, updateUser });
    const isSuperviser = _.find(updateUser.roles, { type: "superviser" });
    const superviser = isSuperviser ? true : false;
    const apps = updateUser.apps.map((app) => app._id);
    this.form.setFieldsValue({ ...updateUser, apps, superviser });
  };
  render() {
    const { loading, isUser, message, updateUser } = this.state;
    const { users, apps, user } = this.props;

    return (
      <Fragment>
        <PageHeader
          ghost={false}
          title="Операторы"
          subTitle="Управляйте вашими операторами и доступами к приложениям"
          extra={[
            <Button key="add" type="primary" onClick={this.newUser}>
              Добавить
            </Button>,
          ]}
        />
        <div className="page__container">
          <Card bordered={false}>
            <Table
              loading={loading}
              size="small"
              className="clients__table"
              columns={this.columns()}
              dataSource={users}
              pagination={false}
              rowKey="_id"
            />
            <Modal
              title="Пользователь"
              visible={isUser}
              onOk={this.handleOk}
              width={700}
              onCancel={this.handleClose}
              forceRender={true}
            >
              {message && (
                <Alert
                  className="user__add__error-alert"
                  message={message}
                  type="error"
                />
              )}
              <Form
                {...layout}
                ref={(f) => (this.form = f)}
                onFinish={this.onFinish}
                validateMessages={validateMessages}
              >
                <Form.Item label="Имя" name="name" rules={[{ required: true }]}>
                  <Input disabled={loading} />
                </Form.Item>
                <Form.Item
                  label="Почтовый адрес"
                  name="email"
                  rules={[{ type: "email" }]}
                >
                  <Input disabled={loading} />
                </Form.Item>
                <Form.Item label="Доступы к приложениям" name="apps">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    disabled={
                      (user.isSuperviser && apps.length === 1) ||
                      (updateUser && updateUser._id === user._id)
                    }
                    placeholder="Выберите приложения из списка"
                  >
                    {apps.map((app) => (
                      <Select.Option key={app._id}>{app.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Супервайзер"
                  name="superviser"
                  valuePropName="checked"
                >
                  <Switch
                    disabled={
                      loading ||
                      ((user.isAdmin || user.isSuperviser) &&
                        updateUser &&
                        updateUser._id === user._id)
                    }
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Card>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  users: state.app.users,
  apps: state.app.apps,
});

export default connect(mapStateToProps, {
  call,
  SET_APPS,
  PUSH_APP,
  SET_APP_BY_PARAM,
  REMOVE_APP_BY_PARAM,
})(Users);
