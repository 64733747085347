import React, { Component } from "react";
import { Modal, Spin } from "antd";
import { YMaps, Map, Placemark } from "react-yandex-maps";

class YMAP extends Component {
  state = {
    loading: true,
    ready: false,
    coords: [43.238949, 76.889709],
  };
  async componentDidMount() {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const { latitude, longitude } = coords;
        this.setState({
          ready: true,
          loading: false,
          coords: [latitude, longitude],
        });
      },
      (e) => {
        this.setState({ loading: false, ready: true });
      },
      options
    );
  }
  coords = (e) => {
    this.setState({ coords: e.get("coords") });
  };
  onSend = async () => {
    const [latitude, longitude] = this.state.coords;
    const values = { latitude, longitude };
    this.setState({ loading: true });
    await this.props.onSend(values);
    this.props.onCancel();
  };
  render() {
    const { coords, loading, ready } = this.state;
    const { onCancel } = this.props;
    return (
      <Modal
        closable={false}
        loading={loading}
        visible={true}
        onCancel={onCancel}
        onOk={this.onSend}
        okText="Отправить"
        bodyStyle={{ padding: 0 }}
      >
        {!ready ? (
          <div style={{ textAlign: "center", padding: 40 }}>
            <Spin />
          </div>
        ) : (
          <YMaps>
            <Map
              width={520}
              height={320}
              state={{ center: coords, zoom: 13 }}
              onClick={this.coords}
            >
              <Placemark geometry={coords} />
            </Map>
          </YMaps>
        )}
      </Modal>
    );
  }
}

export default YMAP;
