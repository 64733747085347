import React, { Fragment } from "react";
import { List } from "antd";
import { connect } from "react-redux";
import { avatarReplacer, formatDate } from "@/utils";

const Comments = ({ comments, loading, onDelete, user }) => (
  <List
    itemLayout="horizontal"
    dataSource={comments}
    loading={loading}
    locale={{
      emptyText: "Нет комментариев",
    }}
    renderItem={(item) => (
      <List.Item key={item._id}>
        <List.Item.Meta
          avatar={avatarReplacer(item.user)}
          title={item.user.name}
          description={
            <Fragment>
              {item.text.split("\n").map((t, i) => (
                <p key={i}>{t}</p>
              ))}
              {user.isAdmin ? (
                <div>
                  <a href="#delete" onClick={onDelete(item._id)}>
                    Удалить
                  </a>
                </div>
              ) : (
                <br />
              )}
              <span className="formatted__date">
                {formatDate(item.createdAt)}
              </span>
            </Fragment>
          }
        />
      </List.Item>
    )}
  />
);

Comments.defaultProps = {
  loading: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Comments);
