import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Tooltip, Table, Modal } from "antd";
import { connect } from "react-redux";
import { call } from "@/actions/axios";
import { SET_APP } from "@/actions/app";
import { UsergroupAddOutlined } from "@ant-design/icons";

class Operators extends Component {
  state = {
    modal: false,
    loading: false,
    selectedRowKeys: [],
    deselectedRowKeys: [],
  };
  load = () => {
    const { users, app } = this.props;
    const appUsers = _.filter(users, (user) => {
      const hasApp = _.find(user.apps, { _id: app._id });
      return !!hasApp;
    }).map((users) => users._id);
    this.setState({ selectedRowKeys: appUsers, modal: true });
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  onSelect = (record, selected) => {
    const { deselectedRowKeys } = this.state;

    let arr = [];
    if (!selected) {
      deselectedRowKeys.push(record._id);
      arr = deselectedRowKeys;
    } else arr = _.pull(deselectedRowKeys, record._id);
    this.setState({ deselectedRowKeys: arr });
  };
  handleSaveUsers = async () => {
    const { selectedRowKeys, deselectedRowKeys } = this.state;
    const { app } = this.props;
    this.setState({ loading: true });
    const users = await this.props.call({
      url: "users/app/" + app._id,
      method: "PATCH",
      data: {
        push: selectedRowKeys,
        pull: deselectedRowKeys,
      },
    });
    this.props.SET_APP(["users"], users.data);
    this.setState({ loading: false, modal: false });
  };
  columns = () => [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];
  render() {
    const { selectedRowKeys, modal, loading } = this.state;
    const { users, app } = this.props;
    if (!app) return null;
    return (
      <Fragment>
        <Tooltip placement="bottom" title="Добавить операторов">
          <UsergroupAddOutlined key="users" onClick={this.load} />
        </Tooltip>
        <Modal
          title="Операторы приложения"
          visible={modal}
          okText="Сохранить"
          onOk={this.handleSaveUsers}
          width={700}
          destroyOnClose={true}
          onCancel={() => this.setState({ modal: false })}
        >
          <Table
            columns={this.columns()}
            loading={loading}
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
              onSelect: this.onSelect,
            }}
            dataSource={users}
            pagination={false}
            rowKey="_id"
          />
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.app.users,
});

export default connect(mapStateToProps, { call, SET_APP })(Operators);
