import React from "react";

const Video = props => (
  <span
    dangerouslySetInnerHTML={{
      __html: videoTagString(props)
    }}
  />
);

Video.defaultProps = {
  muted: true,
  autoPlay: false,
  playsInline: true,
  loop: false,
  controls: true
};

const videoTagString = props => {
  const classOrClassName = props.className || props.class;
  const classString = classOrClassName ? ` class="${classOrClassName}"` : ``;
  const muted = props.muted ? ` muted` : ``;
  const autoPlay = props.autoPlay ? ` autoplay` : ``;
  const playsInline = props.playsInline ? ` playsinline` : ``;
  const loop = props.loop ? ` loop` : ``;
  const poster = props.poster ? ` poster` : ``;
  const src = props.src ? ` src="${props.src}"` : ` src=""`; // required
  const type = props.type ? ` type="${props.type}"` : ``;
  const controls = props.controls ? ` controls` : ``;

  return `<video${controls}${classString}${muted}${autoPlay}${playsInline}${loop}${poster}><source${src}${type}></video>`;
};

export default Video;
