import React, { Component, Fragment } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Table, Modal, Switch, DatePicker, Divider } from "antd";
import { Form, Input, Button, notification } from "antd";
import { Row, Col, Statistic } from "antd";
import { connect, useDispatch } from "react-redux";
import Templates from "./Templates";
import { call } from "@/actions/axios";
import { SET_APP, SET_APP_BY_PARAM, PUSH_APP } from "@/actions/app";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const dateFormat = "YYYY-MM";

const validateMessages = {
  required: "Данное поле обязательно!",
  types: {
    email: "Некорректный формат!",
  },
};

class Companies extends Component {
  state = {
    loading: false,
    company: null,
    app: null,
    templatesModal: false,
    reportsModal: false,
    date: moment().format(dateFormat),
  };
  fetch = async () => {
    const { date } = this.state;
    this.setState({ loading: true });
    const companies = await this.props.call({
      url: "companies?date=" + date,
      method: "GET",
    });
    this.setState({ loading: false });
    this.props.SET_APP(["companies"], companies.data);
  };
  async componentDidMount() {
    await this.fetch();
  }
  expandedRowRender = (c) => {
    const columns = [
      {
        title: "Приложение",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Button type="link" onClick={this.edit(record, "app")}>
            {text}
          </Button>
        ),
      },
      {
        title: "MAU",
        dataIndex: "mau",
        key: "mau",
        render: (mau, record) => record.current_mau + "/" + mau,
      },
      {
        title: "Баланс",
        dataIndex: "balance",
        key: "balance",
      },
      { title: "API KEY", dataIndex: "apiKey", key: "apiKey" },
      { title: "Webhook", dataIndex: "webhook", key: "webhook" },
      {
        title: "Статус",
        dataIndex: "status",
        key: "status",
        render: (val, record) => (
          <Switch
            checked={val}
            onChange={(status) => this.onUpdateApp(record._id, { status })}
          />
        ),
      },
      {
        title: "Шаблоны",
        dataIndex: "templates",
        key: "templates",
        render: (_, record) => (
          <Button type="link" onClick={this.templates(record)}>
            Показать все
          </Button>
        ),
      },
      {
        title: "Отчет",
        dataIndex: "report",
        key: "report",
        render: (_, record) => (
          <Button type="link" onClick={this.report(record)}>
            Выгрузить отчет
          </Button>
        ),
      },
    ];
    return (
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={c.apps}
        pagination={false}
      />
    );
  };
  templates = (app) => () => {
    this.setState({ templatesModal: true, app });
  };
  report = (app) => () => {
    this.setState({ reportsModal: true, app });
  };
  columns = () => [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Button type="link" onClick={this.edit(record, "company")}>
          {text}
        </Button>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={(status) => this.onUpdate(record._id, { status })}
        />
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => this.add(record._id)}>
          Добавить приложение
        </Button>
      ),
    },
  ];
  edit = (record, name) => () => {
    const state = { [name]: record };
    if (name === "app") state.company_id = record.company;
    this[name].setFieldsValue({ ...record });
    this.setState(state);
  };
  onCancel = (name) => () => {
    this[name].resetFields();
    this.setState({
      app: null,
      company: null,
      company_id: null,
      templatesModal: false,
    });
  };
  add = (company_id) => {
    this.setState({ company_id });
  };
  handleOk = () => this.company.submit();
  handleOkApp = () => this.app.submit();
  onUpdate = async (id, values) => {
    this.setState({ loading: true });
    const company = await this.props.call({
      url: "companies/" + id,
      method: "PATCH",
      data: values,
    });
    this.setState({ loading: false });
    this.props.SET_APP_BY_PARAM(["companies"], ["_id", id], company.data);
  };
  onUpdateApp = async (id, values) => {
    this.setState({ loading: true });
    await this.props.call({
      url: "apps/" + id,
      method: "PATCH",
      data: values,
    });
    await this.fetch();
  };
  onFinish = async (values) => {
    try {
      this.setState({ loading: true });
      const { company } = this.state;

      if (company._id) {
        await this.onUpdate(company._id, values);
      } else {
        const { data } = await this.props.call({
          url: "companies/create",
          method: "POST",
          data: values,
        });
        this.props.PUSH_APP(["companies"], data);
      }
      this.setState({ loading: false, company: null });
    } catch (e) {
      this.setState({ loading: false });
      notification.error({
        message: "Ошибка",
        description: e.response.data.message,
      });
    }
  };
  changePeriodMau = async (__, dateString) => {
    await this.setState({ date: dateString });
    await this.fetch();
  };
  onFinishApp = async (values) => {
    try {
      this.setState({ loading: true });
      const { company_id } = this.state;
      values = {
        company_id,
        ...values,
      };
      const { app } = this.state;
      if (app && app._id) {
        await this.onUpdateApp(app._id, values);
      } else {
        await this.props.call({
          url: "apps/create",
          method: "POST",
          data: values,
        });
        await this.fetch();
      }
      this.setState({ loading: false, company_id: null });
    } catch (e) {
      this.setState({ loading: false });
      notification.error({
        message: "Ошибка",
        description: e.response.data.message,
      });
    }
  };
  render() {
    const {
      loading,
      company,
      company_id,
      date,
      templatesModal,
      reportsModal,
      app,
    } = this.state;
    const { companies } = this.props;
    return (
      <Fragment>
        <Button
          type="primary"
          onClick={() =>
            this.setState({
              company: { name: "", description: "", status: false },
            })
          }
        >
          Добавить компанию
        </Button>
        <DatePicker
          style={{ marginLeft: 16 }}
          value={moment(date)}
          onChange={this.changePeriodMau}
          format={dateFormat}
          picker="month"
        />
        <Table
          loading={loading}
          columns={this.columns()}
          dataSource={companies}
          rowKey="_id"
          pagination={false}
          expandable={{ expandedRowRender: this.expandedRowRender }}
        />
        <Modal
          title="Компания"
          visible={!!company}
          width={700}
          onOk={this.handleOk}
          forceRender={true}
          onCancel={this.onCancel("company")}
        >
          <Form
            {...layout}
            ref={(f) => (this.company = f)}
            onFinish={this.onFinish}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="Имя"
              name="name"
              rules={[{ required: true, message: "Поле Имя обязательно" }]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Почтовый адрес"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input disabled={loading} />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Приложение"
          visible={!!company_id}
          width={700}
          onOk={this.handleOkApp}
          forceRender={true}
          onCancel={this.onCancel("app")}
        >
          <Form
            {...layout}
            ref={(f) => (this.app = f)}
            onFinish={this.onFinishApp}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="Имя"
              name="name"
              rules={[{ required: true, message: "Поле Имя обязательно" }]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Телефон"
              name="phone"
              rules={[{ required: true, message: "Поле Телефон обязательно" }]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label="API KEY"
              name="apiKey"
              rules={[{ required: true, message: "Поле API KEY обязательно" }]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item label="MAU" name="mau">
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item label="Баланс" name="balance">
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item label="Webhook" name="webhook">
              <Input disabled={loading} />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Шаблоны"
          visible={templatesModal}
          width="100%"
          destroyOnClose={true}
          forceRender={true}
          footer={null}
          onCancel={() => this.setState({ app: null, templatesModal: false })}
        >
          <Templates app={app} />
        </Modal>
        <Modal
          title="Отчет"
          visible={reportsModal}
          width={500}
          destroyOnClose={true}
          forceRender={true}
          footer={null}
          onCancel={() => this.setState({ app: null, reportsModal: false })}
        >
          <Reports app={app} />
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.app.companies,
});

export default withRouter(
  connect(mapStateToProps, {
    call,
    SET_APP,
    SET_APP_BY_PARAM,
    PUSH_APP,
  })(Companies)
);

const Reports = ({ app }) => {
  const [loading, setLoading] = React.useState(false);
  const [dates, setDates] = React.useState([]);
  const [data, setData] = React.useState({
    total: 0,
    income: 0,
    outcome: 0,
    mau: 0,
    hsm: 0,
  });
  const [reporting, setReporting] = React.useState(false);
  const onChange = (m, d) => {
    setDates(d);
    setReporting(false);
  };

  const dispatch = useDispatch();

  const loadData = async () => {
    setLoading(true);
    const [start_at, end_at] = dates;
    const { data } = await dispatch(
      call({
        url: `report/detailed?startAt=${start_at}&endAt=${end_at}&app_id=${app._id}`,
        method: "GET",
      })
    );
    setData(data);
    setReporting(true);
    setLoading(false);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <DatePicker.RangePicker disabled={loading} onChange={onChange} />
      <Button
        loading={loading}
        style={{ marginLeft: 16 }}
        type="primary"
        onClick={loadData}
      >
        Выгрузить
      </Button>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <Statistic title="Всего сообщений" value={data.total} />
        </Col>
        <Col span={12}>
          <Statistic title="Входящих" value={data.income} />
        </Col>
        <Col span={12}>
          <Statistic title="Исходящих" value={data.outcome} />
        </Col>
        <Col span={12}>
          <Statistic title="MAU" value={data.mau} />
        </Col>
        <Col span={12}>
          <Statistic title="HSM" value={data.hsm} />
        </Col>
      </Row>
    </div>
  );
};
