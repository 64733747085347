import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { call } from "@/actions/axios";
import { SET_APP, GET_CLIENTS } from "@/actions/app";
import Chat from "./Chat";
import "./index.scss";

const { Content } = Layout;

class Home extends Component {
  componentDidMount() {
    this.props.GET_CLIENTS();
    this.props.SET_APP(["clientSelected"], null);
  }
  render() {
    return (
      <Layout>
        <Chat.List />
        <Layout className="page__content__wrapper">
          <Content className="page__content">
            <Chat />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default connect(null, {
  call,
  SET_APP,
  GET_CLIENTS,
})(Home);
