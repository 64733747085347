import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { connect } from "react-redux";
import { SET_APP } from "@/actions/app";
import { LOGOUT } from "@/actions/auth";
import {
  ExportOutlined,
  MessageOutlined,
  LineChartOutlined,
  AppstoreOutlined,
  TeamOutlined,
  BuildOutlined,
} from "@ant-design/icons";

import "./index.scss";

const { Sider } = Layout;

class SiderComp extends Component {
  constructor(props) {
    super(props);
    let { pathname } = props.location;
    let selectedKeys = ["/"];
    if (pathname.indexOf("/apps") !== -1) pathname = "/apps";
    if (pathname !== "/") selectedKeys = [pathname];
    this.state = {
      selectedKeys,
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (state.selectedKeys !== [props.location.pathname]) {
      let { pathname } = props.location;
      if (pathname.indexOf("/apps") !== -1) pathname = "/apps";
      return {
        selectedKeys: [pathname],
      };
    }
    return null;
  }
  render() {
    const { selectedKeys } = this.state;
    const { user } = this.props;

    if (!user) return null;

    return (
      <Sider
        className="sider__item__container"
        collapsed={true}
        collapsedWidth={100}
      >
        <Menu mode="inline" selectedKeys={selectedKeys}>
          <Menu.Item key="/">
            <Link to="/">
              <div className="sider__item">
                <MessageOutlined />
                <div className="sider__item__title">Диалоги</div>
              </div>
            </Link>
          </Menu.Item>
          {user.isAdmin && (
            <Menu.Item key="/apps">
              <Link to="/apps">
                <div className="sider__item">
                  <AppstoreOutlined />
                  <div className="sider__item__title">Приложения</div>
                </div>
              </Link>
            </Menu.Item>
          )}
          {user.isOperator || (
            <Menu.Item key="/users">
              <Link to="/users">
                <div className="sider__item">
                  <TeamOutlined />
                  <div className="sider__item__title">Операторы</div>
                </div>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="/statistic">
            <Link to="/statistic">
              <div className="sider__item">
                <LineChartOutlined />
                <div className="sider__item__title">Статистика</div>
              </div>
            </Link>
          </Menu.Item>
        </Menu>
        <div className="user__menu">
          <Menu mode="inline" selectedKeys={selectedKeys}>
            {user.isSuperAdmin && (
              <Menu.Item key="/admin">
                <Link to="/admin">
                  <div className="sider__item">
                    <BuildOutlined />
                    <div className="sider__item__title">Админ</div>
                  </div>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="/exit" disabled>
              <div className="sider__item" onClick={() => this.props.LOGOUT()}>
                <ExportOutlined />
                <div className="sider__item__title">Выход</div>
              </div>
            </Menu.Item>
            <Menu.Item>
              <Link to="/">
                <div className="logo_replacer">
                  <img alt="logo" src={require("@/assets/images/logo.svg")} />
                </div>
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, { SET_APP, LOGOUT })(SiderComp)
);
