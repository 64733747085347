import React, { Component, Fragment } from "react";
import { PageHeader, Card, Row, Col } from "antd";
import { Tooltip, Switch, notification } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  SendOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Loading } from "@/ui";
import { call } from "@/actions/axios";
import { SET_APPS, SET_APP_BY_PARAM, PUSH_APP } from "@/actions/app";
import Settings from "./Settings";
import Operators from "./Operators";
import { connect } from "react-redux";
import "./index.scss";

class Apps extends Component {
  state = {
    app: null,
    loading: false,
    modal: null,
  };
  async componentDidMount() {
    this.setState({ loading: true });
    const apps = await this.props.call({
      url: "apps",
      method: "GET",
    });
    const users = await this.props.call({
      url: "users",
      method: "GET",
    });
    const arr = [
      { path: ["apps"], value: apps.data },
      { path: ["users"], value: users.data },
    ];
    this.props.SET_APPS(arr);
    this.setState({ loading: false });
  }
  onUpdate = async (id, values) => {
    try {
      const app = await this.props.call({
        url: "apps/" + id,
        method: "PATCH",
        data: values,
      });
      this.props.SET_APP_BY_PARAM(["apps"], ["_id", id], app.data);
    } catch (e) {
      notification.error({
        message: "Ошибка",
        description:
          "Изменения параметров приложения не удалось. Попробуйте еще раз!",
      });
    }
  };
  handleEdit = (app, modal) => () => {
    this.setState({ app, modal });
  };
  reset = () => this.setState({ app: null, modal: null });
  render() {
    const { loading, modal, app } = this.state;
    const { apps } = this.props;

    return (
      <Fragment>
        {loading && <Loading />}
        <PageHeader
          ghost={false}
          title="Мои приложения"
          subTitle="Управляйте вашими приложениями и доступами операторов"
        />
        {modal === "Settings" && (
          <Settings onUpdate={this.onUpdate} app={app} reset={this.reset} />
        )}

        <div className="page__container">
          <Row gutter={[16, 16]}>
            {apps.map((app) => (
              <Col key={app._id} span={8}>
                <Card
                  className="apps__card"
                  bordered={false}
                  extra={
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      checked={app.status}
                      onChange={(status) => this.onUpdate(app._id, { status })}
                    />
                  }
                  actions={[
                    <Operators app={app} />,
                    <Tooltip placement="bottom" title="Настройки приложения">
                      <SettingOutlined
                        key="settings"
                        onClick={this.handleEdit(app, "Settings")}
                      />
                    </Tooltip>,
                    <Tooltip placement="bottom" title="Настроить авто-ответы">
                      <SendOutlined
                        key="replies"
                        onClick={() =>
                          this.props.history.push("/apps/replies/" + app._id)
                        }
                      />
                    </Tooltip>,
                  ]}
                >
                  {app.name}
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  apps: state.app.apps,
  users: state.app.users,
});

export default connect(mapStateToProps, {
  call,
  SET_APPS,
  SET_APP_BY_PARAM,
  PUSH_APP,
})(Apps);
