import React, { Component, Fragment } from "react";
import moment from "moment";
import _ from "lodash";
import queryString from "query-string";
import { connect } from "react-redux";
import { Card, Col, Row, Statistic } from "antd";
import { Table, PageHeader, Button, Badge } from "antd";
import { Select, DatePicker } from "antd";
import { call } from "@/actions/axios";
import { Loading } from "@/ui";
import Download from "./Download";
import "./index.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

class StatisticView extends Component {
  state = {
    loading: false,
    date: [moment(), moment()],
    data: null,
    app: "all",
    reporting: false,
    report: [],
  };
  componentDidMount() {
    this.load();
  }
  load = async () => {
    this.setState({ loading: true, reporting: true });
    const { app, date } = this.state;
    const { apps, user } = this.props;
    const [startAt, endAt] = date;
    let format = "YYYY-MM-DD";
    const params = {
      startAt: startAt.format(format),
      endAt: endAt.format(format),
    };
    const _apps = user.isAdmin ? apps : user.apps;
    if (_apps.length === 1) params.app_id = _apps[0]._id;
    else if (app !== "all") params.app_id = app;
    const { data } = await this.props.call({
      url: "statistic?" + queryString.stringify(params),
      method: "GET",
    });
    let avgAll =
      data.users.length > 0
        ? _.meanBy(data.users, (user) => {
            const { actions } = user;
            if (actions.length === 0) return 0;
            const data = _.filter(actions, (o) => o.assignedAt && o.closedAt);
            if (data.length === 0) return 0;
            return _.meanBy(data, (action) => {
              if (action.closedAt && action.assignedAt) {
                const closedAt = moment(action.closedAt);
                const assignedAt = moment(action.assignedAt);
                return closedAt.diff(assignedAt, "minutes");
              }
              return 0;
            });
          })
        : 0;

    this.setState({ loading: false, data: { ...data, avgAll } });

    await this.download();
  };
  download = async () => {
    const { app, date } = this.state;
    const { apps, user } = this.props;
    const [startAt, endAt] = date;
    let format = "YYYY-MM-DD";
    const params = {
      startAt: startAt.format(format),
      endAt: endAt.format(format),
    };
    const _apps = user.isAdmin ? apps : user.apps;
    if (_apps.length === 1) params.app_id = _apps[0]._id;
    else if (app !== "all") params.app_id = app;
    const { data } = await this.props.call({
      url: "report?" + queryString.stringify(params),
      method: "GET",
    });
    this.setState({ report: data, reporting: false });
  };
  columns = () => [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Онлайн",
      dataIndex: "online",
      key: "online",
      align: "center",
      render: (value) => {
        return <Badge status={value ? "success" : "error"} />;
      },
    },
    {
      title: "Обращений в работе",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (actions) => {
        return _.filter(actions, (o) => o.assignedAt && !o.closedAt).length;
      },
    },
    {
      title: "Оценка",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (actions) =>
        actions.length === 0
          ? 0
          : Math.round(_.meanBy(actions, (a) => a.score)),
    },
    {
      title: "Обработано обращений",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (actions) => {
        return _.filter(actions, (o) => o.assignedAt && o.closedAt).length;
      },
    },
    {
      title: "Среднее время на решение обращения",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (actions) => {
        const data = _.filter(actions, (o) => o.assignedAt && o.closedAt);
        if (data.length === 0) return 0;
        return Math.round(
          _.meanBy(data, (action) => {
            if (action.closedAt && action.assignedAt) {
              const closedAt = moment(action.closedAt);
              const assignedAt = moment(action.assignedAt);
              return closedAt.diff(assignedAt, "minutes");
            }
            return 0;
          })
        );
      },
    },
    {
      title: "Количество заблокированных клиентов",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (actions) => {
        const data = _.filter(actions, (o) => !!o.blockedAt);
        return data.length;
      },
    },
  ];
  renderAvgTime = (value) => {
    if (value < 1) return "Меньше минуты";
    else if (value < 2) return "До двух минут";
    else return Math.round(value) + " минут";
  };
  disabledDate = (current) => {
    const { date } = this.state;
    if (!date || date.length === 0) return false;
    const tooLate = date[0] && current.diff(date[0], "days") > 90;
    const tooEarly = date[1] && date[1].diff(current, "days") > 90;
    return tooEarly || tooLate;
  };
  render() {
    const { loading, date, app, data } = this.state;
    const { reporting, report } = this.state;
    const { apps, user } = this.props;

    if (!data) return <Loading />;

    const _apps = user.isAdmin ? apps : user.apps;

    return (
      <Fragment>
        {loading && <Loading />}
        <div className="page-header__wrapper">
          <PageHeader
            ghost={false}
            className="card"
            title="Статистика"
            extra={
              user.isAdmin && <Download loading={reporting} data={report} />
            }
            subTitle="Воспользуйтесь фильтрами для просмотра клиентских в разрезе даты, сервисов"
          >
            <Row>
              {_apps.length > 1 && (
                <Select
                  onChange={(app) => this.setState({ app })}
                  value={app}
                  style={{ width: 120, marginRight: 16 }}
                >
                  <Option value="all">Все</Option>
                  {_apps.map((app) => (
                    <Option key={app._id} value={app._id}>
                      {app.name}
                    </Option>
                  ))}
                </Select>
              )}
              <RangePicker
                value={date}
                format="DD.MM.YYYY"
                style={{ marginRight: 16 }}
                allowClear={false}
                disabledDate={this.disabledDate}
                onCalendarChange={(date) => this.setState({ date })}
              />
              <Button
                shape="round"
                key="refresh"
                type="primary"
                onClick={this.load}
              >
                Обновить
              </Button>
            </Row>
          </PageHeader>
        </div>
        <div className="page__container">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card className="card">
                <Statistic title="Всего обращений" value={data.all} />
              </Card>
            </Col>
            <Col span={8}>
              <Card className="card">
                <Statistic
                  title="Обработано обращений"
                  value={data.closedAll}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card className="card">
                <Statistic
                  title="Среднее время решения обращении"
                  value={this.renderAvgTime(data.avgAll)}
                />
              </Card>
            </Col>
          </Row>
          <Card style={{ border: "none" }}>
            <Table
              columns={this.columns()}
              bordered={false}
              dataSource={data.users}
              size="small"
              pagination={false}
              rowKey="_id"
            />
          </Card>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  apps: state.app.apps,
  user: state.auth.user,
});

export default connect(mapStateToProps, { call })(StatisticView);
