export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const REFRESH = "REFRESH";

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";

export const SET_AUTH = "SET_AUTH";

export const SET_APP = "SET_APP";
export const SET_APPS = "SET_APPS";
export const SET_APP_BY_PARAM = "SET_APP_BY_PARAM";
export const PUSH_APP = "PUSH_APP";
export const UNSHIFT_APP = "UNSHIFT_APP";
export const REMOVE_APP_BY_PARAM = "REMOVE_APP_BY_PARAM";
