import React, { Component, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Layout, List, Tooltip } from "antd";
import { Badge, Radio, Input, Empty } from "antd";
import { connect } from "react-redux";
import { SET_APP, GET_MESSAGES } from "@/actions/app";
import { GET_CLIENTS, GET_CLIENTS_MORE } from "@/actions/app";
import {
  AudioOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  PushpinOutlined,
  SearchOutlined,
  CloseCircleFilled,
  InboxOutlined,
} from "@ant-design/icons";
import { Loading } from "@/ui";
import { formatDate, avatarReplacer } from "@/utils";
import "./index.scss";

const { Sider } = Layout;

class ListComponent extends Component {
  state = {
    search: "",
  };
  select = (client) => () => {
    this.props.SET_APP(["clientSelected"], client);
    this.props.GET_MESSAGES();
  };
  onChange = async (e) => {
    this.props.SET_APP(["clientType"], e.target.value);
    this.props.GET_CLIENTS();
  };
  header = () => {
    const { type, allMessages, myMessages } = this.props;
    return (
      <Fragment>
        <Radio.Group
          className="chat__types"
          buttonStyle="solid"
          value={type}
          onChange={this.onChange}
        >
          <Badge count={allMessages}>
            <Radio.Button value="all">Новые</Radio.Button>
          </Badge>
          <Badge count={myMessages}>
            <Radio.Button value="my">Мои</Radio.Button>
          </Badge>
          <Badge className="icon__tab">
            <Radio.Button value="archive">
              <Tooltip placement="bottom" title="Архив">
                <InboxOutlined />
              </Tooltip>
            </Radio.Button>
          </Badge>
        </Radio.Group>
      </Fragment>
    );
  };
  description = ({ type, text }) => {
    let icon;
    if (type === "audio") icon = <AudioOutlined />;
    else if (type === "image") icon = <FileImageOutlined />;
    else if (type === "video") icon = <VideoCameraOutlined />;
    else if (type === "location") icon = <PushpinOutlined />;
    else if (type === "document") icon = <FileTextOutlined />;

    if (icon) {
      return (
        <Fragment>
          {icon} {text}
        </Fragment>
      );
    }
    return text;
  };
  onLoad = (page) => {
    this.props.GET_CLIENTS_MORE(page);
  };
  search = (e) => {
    clearTimeout(this.timer);
    this.props.SET_APP(["search"], e.target.value);
    this.timer = setTimeout(() => {
      this.props.GET_CLIENTS();
    });
  };
  clear = () => {
    this.props.SET_APP(["search"], "");
    this.props.GET_CLIENTS();
  };
  render() {
    const { loading, clients, selected, search } = this.props;
    const { type } = this.props;

    return (
      <Sider theme="light" width={300} className="sider__messages">
        <div className="chat__types__header">
          <Input
            className="search__input"
            onChange={this.search}
            placeholder="Поиск"
            value={search}
            suffix={
              search ? (
                <CloseCircleFilled onClick={this.clear} />
              ) : (
                <SearchOutlined style={{ fontSize: 16 }} />
              )
            }
          />
          {this.header()}
        </div>
        <div className="chat__clients">
          {clients.data.length === 0 && loading ? (
            <Loading />
          ) : (
            <InfiniteScroll
              initialLoad={false}
              pageStart={1}
              loadMore={this.onLoad}
              hasMore={!loading && clients.hasNextPage}
              useWindow={false}
            >
              <List
                itemLayout="horizontal"
                loading={loading}
                dataSource={clients.data}
                locale={{
                  emptyText: (
                    <Empty
                      image={require("@/assets/images/no-dialogs.png")}
                      imageStyle={{
                        marginTop: 50,
                        height: 200,
                      }}
                      description="Нет диалогов"
                    ></Empty>
                  ),
                }}
                renderItem={(item) => {
                  return (
                    <List.Item
                      onClick={this.select(item)}
                      className={
                        selected && selected._id === item._id ? "selected" : ""
                      }
                    >
                      <List.Item.Meta
                        className="list__meta"
                        avatar={<Fragment>{avatarReplacer(item)}</Fragment>}
                        title={item.name}
                        description={
                          <Fragment>
                            {item.message && this.description(item.message)}
                            <div className="formatted__date">
                              {formatDate(item.message.createdAt)}
                            </div>
                          </Fragment>
                        }
                      />
                      {type === "archive" || <Badge count={item.unread} />}
                    </List.Item>
                  );
                }}
              />
            </InfiniteScroll>
          )}
        </div>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => ({
  search: state.app.search,
  clients: state.app.clients,
  type: state.app.clientType,
  selected: state.app.clientSelected,
  loading: state.app.clientsLoading,
  allMessages: state.app.allMessages,
  myMessages: state.app.myMessages,
});

export default connect(mapStateToProps, {
  SET_APP,
  GET_CLIENTS,
  GET_CLIENTS_MORE,
  GET_MESSAGES,
})(ListComponent);
