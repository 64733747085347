import React, { Component } from "react";
import { connect } from "react-redux";
import { call } from "@/actions/axios";
import { SET_APP_BY_PARAM, SET_APP } from "@/actions/app";
import { Layout, Divider } from "antd";
import { Input, Button } from "antd";
import { Comments, Pagination } from "@/ui";
import axios from "axios";
import "./index.scss";

const { Sider } = Layout;
const { TextArea } = Input;
const CancelToken = axios.CancelToken;
let cancel;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: "",
      commentsLoading: true,
      commentPending: false,
      page: 1,
    };
  }
  async componentDidMount() {
    await this.fetch();
  }
  componentWillUnmount() {
    if (cancel) cancel();
  }
  componentDidUpdate(props) {
    if (props.selected && props.selected._id !== this.props.selected._id) {
      this.fetch();
    }
  }
  keySubmit = ({ nativeEvent: e }) => {
    if ((e.ctrlKey || e.metaKey) && (e.keyCode === 13 || e.keyCode === 10)) {
      this.comment();
    }
  };
  fetch = async () => {
    const { selected } = this.props;
    if (!selected) return;
    const { page } = this.state;
    this.setState({ commentsLoading: true });
    const comments = await this.props.call({
      url: "clients/" + selected._id + "/comments?page=" + page,
      method: "GET",
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    this.props.SET_APP(["comments"], comments.data);
    this.setState({ commentsLoading: false });
  };
  comment = async () => {
    const { selected } = this.props;
    const { commentText } = this.state;
    if (!commentText) return;
    this.setState({ commentPending: true });
    await this.props.call({
      url: "clients/" + selected._id + "/comment",
      method: "POST",
      data: { commentText },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    await this.fetch();
    this.setState({ commentPending: false, commentText: "" });
  };
  onDelete = (id) => async () => {
    const { selected } = this.props;
    this.setState({ commentPending: true });
    await this.props.call({
      url: "clients/" + selected._id + "/comment",
      method: "DELETE",
      data: { id },
    });
    await this.fetch();
    this.setState({ commentPending: false });
  };
  onPage = async (val) => {
    const { page } = this.state;
    await this.setState({ page: page + val });
    await this.fetch();
  };
  render() {
    const { selected } = this.props;

    if (!selected) return null;
    const { commentText, commentPending, commentsLoading } = this.state;
    const { comments } = this.props;
    return (
      <Sider className="profile__short" width={260} theme="light">
        <div className="comments">
          <div className="comments__title">Добавить комментарии</div>
          <TextArea
            onChange={(e) => this.setState({ commentText: e.target.value })}
            placeholder="Комментарий"
            value={commentText}
            onKeyDown={this.keySubmit}
            disabled={commentPending}
          />
          <Button
            disabled={commentPending}
            block={true}
            onClick={this.comment}
            shape="round"
            type="primary"
          >
            Сохранить комментарий
          </Button>
          <Divider />
          <div className="comments__header">
            <div className="comments__title">Комментарии</div>
          </div>
          <div className="comments__pagination">
            <Pagination {...comments} onPage={this.onPage} />
          </div>
          <div className="comments__wrapper">
            <Comments
              comments={comments.data}
              loading={commentPending || commentsLoading}
              onDelete={this.onDelete}
            />
          </div>
        </div>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => ({
  selected: state.app.clientSelected,
  comments: state.app.comments,
});

export default connect(mapStateToProps, {
  call,
  SET_APP_BY_PARAM,
  SET_APP,
})(Profile);
