import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { AUTH } from "@/actions/auth";
import queryString from "query-string";
import { call } from "@/actions/axios";
import { Form, Input, Button, notification } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { Row, Col, Alert } from "antd";

import "./index.scss";

class ChangePassword extends Component {
  state = {
    loading: false,
  };
  async componentDidMount() {
    const { location, call } = this.props;
    if (!location.search) this.props.history.replace("/");
    const query = queryString.parse(location.search);
    if (!query.token) this.props.history.replace("/");
    try {
      await call({
        url: "auth/validate",
        method: "POST",
        data: query,
      });
      this.setState({ token: query.token });
    } catch (e) {
      this.props.history.replace("/");
    }
    const [el] = document.getElementsByClassName("main__content__layout");
    el.classList.add("login");
  }
  componentWillUnmount() {
    const [el] = document.getElementsByClassName("main__content__layout");
    el.classList.remove("login");
  }
  handleSubmit = async (values) => {
    if (values.password !== values.password_confirm) {
      return this.setState({
        message: "Пароли не совпадают",
      });
    }
    const { token } = this.state;
    values.token = token;
    try {
      await this.props.AUTH("auth/change_password", values, false);
      notification.success({
        message: "Пароль изменен",
        description: "Войдите в систему используя новый пароль",
      });
      this.props.history.replace("/");
    } catch (e) {
      let message = "Неизвестная ошибка";
      const m = _.get(e, ["repsonse", "data", "message"]);
      if (m) message = m;
      this.setState({ message });
    }
  };
  render() {
    const { isLoading } = this.props;
    const { message } = this.state;
    return (
      <Row
        justify="center"
        align="middle"
        type="flex"
        className="login-form-container"
      >
        <Col xl={6} xxl={6} lg={8} md={8} className="login-form-box">
          <Form onFinish={this.handleSubmit} className="login-form">
            <Form.Item className="login-form-logo">
              <NavLink to="/" className="logo">
                <img alt="logo" src={require("@/assets/images/logo.svg")} />
              </NavLink>
            </Form.Item>
            {message && (
              <Form.Item>
                <Alert message={message} type="error" showIcon />
              </Form.Item>
            )}
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Введите пароль!" }]}
            >
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Придумайте пароль"
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              name="password_confirm"
              rules={[{ required: true, message: "Повторите пароль!" }]}
            >
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Повторите пароль"
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={isLoading}
              >
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, { AUTH, call })(ChangePassword);
