import React, { Component, Fragment } from "react";
import { Card } from "antd";
import { Companies } from "./Tabs";
import "./index.scss";

class Admin extends Component {
  render() {
    return (
      <Fragment>
        <Card>
          <Companies />
        </Card>
      </Fragment>
    );
  }
}

export default Admin;
