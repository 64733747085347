import React, { Component } from "react";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Download extends Component {
  button = () => (
    <Button
      loading={this.props.loading}
      shape="round"
      key="refresh"
      type="primary"
      onClick={this.download}
    >
      <UploadOutlined />
      Скачать отчет
    </Button>
  );
  render() {
    const { data } = this.props;

    const dateFormat = "DD.MM.YYYY HH:mm:ss";

    return (
      <ExcelFile element={this.button()} filename="Статистика">
        <ExcelSheet data={data} name="Статистика">
          <ExcelColumn
            label="ID КЛИЕНТА"
            value={(col) => (col.client ? col.client._id : "")}
          />
          <ExcelColumn
            label="ИМЯ КЛИЕНТА"
            value={(col) => (col.client ? col.client.name : "")}
          />
          <ExcelColumn
            label="ТЕЛЕФОН КЛИЕНТА"
            value={(col) => (col.client ? col.client.phone : "")}
          />
          <ExcelColumn
            label="ОЦЕНКА КЛИЕНТА"
            value={(col) => (col.score ? col.score : "0")}
          />
          <ExcelColumn
            label="ID ОПЕРАТОРА"
            value={(col) => (col.user ? col.user._id : "")}
          />
          <ExcelColumn
            label="ИМЯ ОПЕРАТОРА"
            value={(col) => (col.user ? col.user.name : "")}
          />
          <ExcelColumn
            label="ПРИЛОЖЕНИЕ"
            value={(col) => (col.app ? col.app.name : "")}
          />
          <ExcelColumn
            label="НОМЕР ПРИЛОЖЕНИЯ"
            value={(col) => (col.app ? col.app.phone : "")}
          />
          <ExcelColumn
            label="ДАТА СОЗДАНИЯ"
            value={(col) =>
              col.createdAt ? moment(col.createdAt).format(dateFormat) : ""
            }
          />
          <ExcelColumn
            label="ДАТА ПОСЛЕДНЕГО СООБЩЕНИЯ"
            value={(col) =>
              col.client
                ? moment(col.client.message.createdAt).format(dateFormat)
                : ""
            }
          />
          <ExcelColumn
            label="ДАТА ЗАКРЕПЛЕНИЯ"
            value={(col) =>
              col.assignedAt ? moment(col.assignedAt).format(dateFormat) : ""
            }
          />
          <ExcelColumn
            label="ДАТА ЗАКРЫТИЯ"
            value={(col) =>
              col.closedAt ? moment(col.closedAt).format(dateFormat) : ""
            }
          />
          <ExcelColumn
            label="ДАТА НЕАКТИВНОСТИ"
            value={(col) =>
              col.inactiveAt ? moment(col.inactiveAt).format(dateFormat) : ""
            }
          />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default Download;
