import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import auth from "./reducers/auth";
import app from "./reducers/app";

const configureStore = composeWithDevTools(applyMiddleware(thunkMiddleware))(
  createStore
);

const authPersistConfig = {
  key: "auth_v2",
  version: "2",
  storage: storage,
  blacklist: ["message", "status", "auth"],
};

const appPersistConfig = {
  key: "app_v2",
  version: "2",
  storage: storage,
};

const reducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  app: persistReducer(appPersistConfig, app),
});

export default () => {
  const store = configureStore(reducer);
  const persistor = persistStore(store);

  return { persistor, store };
};
